import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Config } from '../../../core/models/template.model';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
   
    constructor(private http: HttpClient) { }

    getConfigurations(tenant: string, configName: string, app: string, brand?:string): Observable<any> {
        return this.http.get<Config[]>(`${'/tr-api/admin/getconfigurations?tenant='}${tenant}&configName=${configName}&app=${app}&brand=${brand}`).pipe(
            map(object => object.map((res): Config => ({
                id: res.id,
                name: res.name,
                value: res.value,
                application: res.application
            })))
        );
    }
}
